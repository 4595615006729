// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-jsx": () => import("C:\\Projects\\422B-website-new\\422B-website\\app_frontend\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Projects\\422B-website-new\\422B-website\\app_frontend\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import("C:\\Projects\\422B-website-new\\422B-website\\app_frontend\\.cache\\data.json")

